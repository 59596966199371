import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'
import Container from '../../container'
import { FullWidthContainer } from '../page-hero-slider'
const TextureSrc = require('../../../assets/texture.svg') as string

interface DirectorySearchProps {
  className?: string
  onSearch?: (filters: SearchFilters) => void
  companies?: Array<{
    category?: string
    city?: string
  }>
}

export interface SearchFilters {
  keyword: string
  category: string
  location: string
}

const Wrapper = styled.div`
  background-image: url(${TextureSrc});
  color: ${theme.colours.white};
  padding: 12rem 0;
`

const Title = styled.h2`
  font-size: 4rem;
  font-weight: 900;
  font-family: ${theme.fonts.primaryBlack};
  line-height: 140%;
  margin: 0;
`

const Subtitle = styled.p`
  font-size: 1.8rem;
  line-height: 140%;
  max-width: 60rem;
  margin: 0 auto;
`

const InfoText = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`

const SearchFields = styled.div`
  display: flex;
  gap: 2rem;
  max-width: 120rem;
  margin: 0 auto;
  align-items: stretch;

  @media ${theme.breakpoints.toMediumScreen} {
    flex-direction: column;
  }
`

const Input = styled.input`
  flex: 2;
  padding: 1.6rem 2rem;
  border: none;
  border-radius: 0.4rem;
  font-family: ${theme.fonts.primary};
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey};
  background: ${theme.colours.white};

  &::placeholder {
    color: ${theme.colours.darkGrey2};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colours.primary};
  }
`

const Select = styled.select`
  flex: 1;
  padding: 1.6rem 2rem;
  border: none;
  border-radius: 0.4rem;
  font-family: ${theme.fonts.primary};
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey};
  background: ${theme.colours.white};
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 1.5rem;
  padding-right: 4rem;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colours.primary};
  }
`

const SearchButton = styled.button`
  padding: 1.6rem 4rem;
  background: ${theme.colours.quaternary};
  color: ${theme.colours.white};
  border: none;
  border-radius: 0.4rem;
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: ${theme.colours.primaryHover};
  }

  @media ${theme.breakpoints.toMediumScreen} {
    width: 100%;
  }
`

const DirectorySearch: React.FC<DirectorySearchProps> = ({
  className,
  onSearch,
  companies = [],
}) => {
  const uniqueCategories = Array.from(
    new Set(
      companies
        .map((company) => company.category)
        .filter((category): category is string => !!category),
    ),
  ).sort()

  const uniqueCities = Array.from(
    new Set(
      companies
        .map((company) => company.city)
        .filter((city): city is string => !!city),
    ),
  ).sort()
  const [keyword, setKeyword] = useState('')
  const [category, setCategory] = useState('')
  const [location, setLocation] = useState('')

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault()
    onSearch?.({
      keyword,
      category,
      location,
    })
  }

  return (
    <FullWidthContainer>
      <Wrapper className={className}>
        <Container>
          <InfoText>
            <Title>Find & explore businesses from our community</Title>
            <Subtitle>
              Welcome to the online directory of Pacific Business Trust
              businesses where you find and connect with our members
            </Subtitle>
          </InfoText>
          <form onSubmit={handleSearch}>
            <SearchFields>
              <Input
                type='text'
                placeholder='Search by keyword'
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value=''>Category</option>
                {uniqueCategories.map((cat) => (
                  <option key={cat} value={cat.toLowerCase()}>
                    {cat}
                  </option>
                ))}
              </Select>
              <Select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value=''>Location</option>
                {uniqueCities.map((city) => (
                  <option key={city} value={city.toLowerCase()}>
                    {city}
                  </option>
                ))}
              </Select>
              <SearchButton type='submit'>SEARCH</SearchButton>
            </SearchFields>
          </form>
        </Container>
      </Wrapper>
    </FullWidthContainer>
  )
}

export default DirectorySearch
