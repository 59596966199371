import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Spinner from '../components/atoms/spinner'
import theme from '../components/atoms/theme'
import { LAMBDA_URL } from '../components/constants'
import Layout from '../components/layout'
import DirectoryCategories, {
  Title,
} from '../components/organisms/directory/directory-categories'
import DirectoryListings from '../components/organisms/directory/directory-listings'
import DirectorySearch, {
  SearchFilters,
} from '../components/organisms/directory/directory-search'
import { Company } from '../types/company'

const SpinnerContainer = styled.div`
  padding: 8rem;
  text-align: center;
`

const spinnerStyle = {
  height: '20rem',
}

interface FluidObject {
  aspectRatio: number
  src: string
  srcSet: string
  sizes: string
  base64?: string
  tracedSVG?: string
  srcWebp?: string
  srcSetWebp?: string
  media?: string
}

interface Props {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    allContentfulDirectoryAd: {
      edges: Array<{
        node: {
          id: string
          image: {
            fluid: FluidObject
            title: string
          }
          buttonLabel: string
          link: string
          order: number
        }
      }>
    }
  }
}

const Directory = (props: Props): JSX.Element => {
  const currentPath = props.location.pathname || '/'
  const siteTitle = props.data.site.siteMetadata.title

  const [isLoading, setLoading] = useState(true)
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [searchFilters, setSearchFilters] = useState<SearchFilters>({
    keyword: '',
    category: '',
    location: '',
  })

  const query = `
    query accountsQuery($limit: Int, $offset: Int) {
      accounts(limit: $limit, offset: $offset) {
        nodes {
          aboutUs
          businessHours
          category
          city
          companyLogo
          companyLogoURL
          companyName
          consented
          contactPerson
          emailAddress
          id
          image
          phoneNumber
          postcode
          serviceProductsList
          servicesProducts
          shortDescription
          sponsored
          street
          websiteSocialMediaLink
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `

  useEffect(() => {
    fetch(LAMBDA_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        variables: {
          limit: null,
          offset: 0,
        },
      }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        setCompanies(resultData.data.accounts.nodes)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching companies:', error)
        setLoading(false)
      })
  }, [])

  const filterCompanies = (): Company[] => {
    const filtered = companies.filter((company) => {
      // Category filter from category buttons
      if (selectedCategory && company.category !== selectedCategory) {
        return false
      }

      // Search filters
      if (searchFilters.keyword) {
        const searchTerm = searchFilters.keyword.toLowerCase()
        const matchesKeyword =
          company.companyName?.toLowerCase().includes(searchTerm) ||
          company.shortDescription?.toLowerCase().includes(searchTerm) ||
          false
        if (!matchesKeyword) return false
      }

      if (
        searchFilters.category &&
        company.category?.toLowerCase() !== searchFilters.category.toLowerCase()
      ) {
        return false
      }

      if (
        searchFilters.location &&
        !company.city
          ?.toLowerCase()
          .includes(searchFilters.location.toLowerCase())
      ) {
        return false
      }

      return true
    })

    // Sort to show sponsored accounts first
    return filtered.sort((a, b) => {
      if (a.sponsored && !b.sponsored) return -1
      if (!a.sponsored && b.sponsored) return 1
      return 0
    })
  }

  const handleSearch = (filters: SearchFilters) => {
    setSearchFilters(filters)
  }

  const handleCategorySelect = (categoryId: string | null) => {
    setSelectedCategory(categoryId)
  }

  const filteredCompanies = filterCompanies()

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <DirectorySearch onSearch={handleSearch} companies={companies} />
      {isLoading ? (
        <SpinnerContainer>
          <Spinner style={spinnerStyle} alternative={true} />
        </SpinnerContainer>
      ) : (
        <>
          <Title>Find by categories</Title>
          <DirectoryCategories
            onCategorySelect={handleCategorySelect}
            selectedCategory={selectedCategory}
            companies={companies}
          />
          <Title>Companies</Title>
          <DirectoryListings
            companies={filteredCompanies}
            ads={props.data.allContentfulDirectoryAd.edges.map(
              (edge) => edge.node,
            )}
          />
        </>
      )}
    </Layout>
  )
}

export default Directory

export const pageQuery = graphql`
  query DirectoryQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDirectoryAd {
      edges {
        node {
          id
          image {
            ...GatsbyFluidImageFields
            title
          }
          buttonLabel
          link
          order
        }
      }
    }
  }
`
